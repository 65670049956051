import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>©2024 Pavan Chhalani</p>
            <a href="/about">About</a>
            <a href="/contact">Contact</a>
            <a href="/terms-of-service">Terms of Service</a>
            <a href="/privacy-policy">Privacy Policy</a>
        </footer>
    );
};

export default Footer;
